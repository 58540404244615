import * as React from "react";

import Select, {SelectChangeEvent} from "@mui/material/Select";
import {useRef, useState} from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {SiriusRTC} from "@nete2/sirius-rtc-sdk-ess";

const webrtcSampleUrl = "wss://domain/webrtc-session.json/token"
const websocketSampleUrl = "wss://domain/live?id=token"
const SiriusRTCPlayer = () => {
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const [status, setStatus] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [streamer, setStreamer] = useState<any>();
  const [url, setUrl] = useState(webrtcSampleUrl);
  const [selectedStreamType, setSelectedStreamType] = React.useState("webrtc"); //webrtc or websocket

  const handleOnStreamTypeChanged = (event: SelectChangeEvent) => {
    setSelectedStreamType(event.target.value as string);
    if (event.target.value == "webrtc") {
      setUrl(webrtcSampleUrl);
    } else {
      setUrl(websocketSampleUrl);
    }
    if (streamer) handleOnStop();
    setStatus("")
  };

  const handleOnPlay = () => {
    console.log("-------- handleOnPlay --------");
    const playSettings = {
      url: url,
    };

    console.log("createPlayStrem for streamType -> %o", selectedStreamType);
    let stream = SiriusRTC.createPlayStream({
      settings: playSettings,
      videoElement: videoElementRef.current,
      streamType: selectedStreamType,
    })
      .on(SiriusRTC.STREAM_EVENT.CONNECTING, (stream: any) => {
        setStatus("Connecting....");
      })
      .on(SiriusRTC.STREAM_EVENT.CONNECTED, (stream: any) => {
        setStatus("Playing....");
        setIsPlaying(true);
      })
      .on(SiriusRTC.STREAM_EVENT.STOPPING, (stream: any) => {
        setStatus("Stopping....");
        setIsPlaying(false);
      })
      .on(SiriusRTC.STREAM_EVENT.STOPPED, (stream: any) => {
        setStatus("Stopped.");
        setIsPlaying(false);
      })
      .on(SiriusRTC.STREAM_EVENT.CONNECT_FAILED, (stream: any, error: any) => {
        console.log(error.message)
        setStatus("Failed. " + error.message);
        setIsPlaying(false);
      })
      .on(SiriusRTC.STREAM_EVENT.AUTH_FAILED, (stream: any, error: any) => {
        setStatus("Auth Failed. " + error.message);
        setIsPlaying(false);
      })
      .on(SiriusRTC.STREAM_EVENT.CLOSED, (stream: any, data: any) => {
        let info = JSON.parse(data);
        console.log("StreamClosed %o", info); 
        setIsPlaying(false);  
          if (info.message) {
            setStatus(info.message)
          }
      });

    stream.play();
    setStreamer(stream);
  };

  const handleOnStop = () => {
    console.log("-------- handleOnStop --------");
    if (streamer) streamer.stop();
    setStreamer(null);
    setIsPlaying(false);
  };

  return (
    <div className="player">
      <div className="banner">
        <h2>Sirius-SDK-Demo</h2>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">StreamType</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" label="StreamType" value={selectedStreamType} onChange={handleOnStreamTypeChanged}>
            <MenuItem value={"webrtc"}>WebRTC</MenuItem>
            <MenuItem value={"websocket"}>Websocket</MenuItem>
          </Select>
        </FormControl>
      </div>
      <br />

      <div className="content">
        <div className="video_container">
          <video className="video" ref={videoElementRef} style={{backgroundColor: "black"}} controls autoPlay muted></video>
        </div>
        <label>URL</label>
        <span className="status" style={{float: "right"}}>
          {status}
        </span>
        <br />

        <input className="input" readOnly={isPlaying} value={url} onChange={(e) => setUrl(e.target.value)} type="text" name="url" />
        <br />
        <button className="btn" disabled={isPlaying} onClick={handleOnPlay}>
          Play
        </button>
        <button className="btn" disabled={!isPlaying} onClick={handleOnStop}>
          Stop
        </button> 
      </div>
    </div>
  );
};

export default SiriusRTCPlayer;
