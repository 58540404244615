import { BrowserRouter, Route, Routes } from "react-router-dom";

import Player from "./Player";

function App() {

  return (
    <BrowserRouter >
      <Routes>
        <Route path="/*" element={<Player />} />
      </Routes>
    </BrowserRouter >
  );
}

export default App;
